/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import Main from "./main"

import "typeface-playfair-display"
import "typeface-merriweather"
import "../normalize.css"
import "./layout.scss"

const Layout = ({ heading, children }) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);
    const siteTitle = data.site.siteMetadata.title;

    return (
        <>
            <Header siteTitle={siteTitle} />
            <Main heading={heading}>
                {children}
            </Main>
            <Footer siteTitle={siteTitle} />
        </>
    )
}

Layout.propTypes = {
    heading: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
}

export default Layout
