import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Footer = ({ siteTitle }) => (
    <footer className="siteFooter" role="contentinfo">
        <p className="siteFooter_copyright">
            <span className="siteFooter_copyright_date">&copy; {new Date().getFullYear()} </span>
            <span className="siteFooter_copyright_text">{siteTitle}</span>
        </p>
        <nav className="siteFooter_sitemap">
            <ul>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/services">Services</Link></li>
                <li><Link to="/about">About</Link></li>
            </ul>
        </nav>
    </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
