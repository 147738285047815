import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Text from "../components/text"

const AboutPage = () => (
    <Layout heading="About Jim Osborn">
        <SEO title="About my web development and consultancy history" />
        <Text>
            <p>My web development career started in 2001 at a web design and marketing agency. In fact, most of my working life has been spent on the agency side.</p>
            <p>I have a degree in Computer Science (Bsc) that has given me a good grounding in programming as a whole but most of my specific knowledge is self-taught.</p>
            <p>Most of my early skills where buit around in Microsoft’s technology but I’ve recently been branching out into other areas such as PHP, WordPress and React.</p>
            <p>Please <a href="mailto:info@thinktherefore.co.uk">get in touch</a> if there’s something I can help with.</p>
        </Text>
    </Layout>
)

export default AboutPage
