import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import logoImage from "../images/logo-thinktherefore.png"

const Header = ({ siteTitle }) => (
    <header className="siteHeader" role="banner">
        <div className="siteHeader_logo">
            <Link to="/"><img src={logoImage} alt={siteTitle} /></Link>
        </div>
        <div className="siteHeader_contact">
            <a href="mailto:info@thinktherefore.co.uk">info@thinktherefore.co.uk</a>
        </div>
        <nav className="siteHeader_nav">
            <ul>
                <li><Link to="/services">Services</Link></li>
                <li><Link to="/about">About</Link></li>
            </ul>
        </nav>
    </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
