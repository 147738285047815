import React from "react"

const Main = ({ heading, children }) => {
    return (
        <main className="siteMain">
            <div className="siteMain_header">
                <h1>{heading}</h1>
            </div>
            <div className="siteMain_content">
                {children}
            </div>
        </main>
    )
}

export default Main