import React from "react"

const Text = ({ children }) => {
    return (
        <div className="text">
            {children}
        </div>
    )
}

export default Text